<template>
  <article :class="`subscription-plan plans__item panel ${plan.id}`">
    <header class="plans__item__header">
      <div class="plans__item__title">{{ plan.name }}</div>
      <div class="plans__item__price plan-price">
        <ng-price
          :amount="finalPrice.amount"
          :currency="finalPrice.currency"
          :yearly="isYearly"
          :monthly="!isYearly"
          is-formatted
        ></ng-price>
      </div>
    </header>
    <dl class="plans__feature list-unstyled">
      <SubscriptionPlanFeature
        v-for="(feature, index) in description.topList"
        :feature="feature"
        :key="`topList-${index}`"
      />
    </dl>
    <dl class="plans__feature plans__feature--bottom list-unstyled">
      <dt class="plans__feature__subtitle">{{ $t('subscription.clientSoftware') }}</dt>
      <SubscriptionPlanFeature
        v-for="(feature, index) in description.bottomList"
        :feature="feature"
        :key="`bottomList-${index}`"
      />
    </dl>
    <div class="plans__item__btn text-center">
      <template v-if="isAutopaymentActive">
        <ng-button
          v-if="isActive && !isPeriodDifferent"
          outlined
          large
          :color="inactiveButtonColor"
        >
          {{ $t('subscription.yourPlan') }}
        </ng-button>
        <ng-button
          v-else-if="isActive && isPeriodDifferent"
          large
          color="light"
          @click="selectPlan"
        >
          {{ $t('subscription.changeInterval') }}
        </ng-button>
        <ng-button v-else color="light" large @click="selectPlan">
          {{ $t('app.select') }}
        </ng-button>
      </template>
      <template v-if="!isAutopaymentActive">
        <ng-button
          v-if="plan.id === 'free' && isActive"
          outlined
          large
          :color="inactiveButtonColor"
        >
          {{ $t('subscription.yourPlan') }}
        </ng-button>
        <ng-button
          v-else-if="plan.id === 'free' && canceledAt"
          outlined
          large
          :color="inactiveButtonColor"
        >
          {{ $t('subscription.yourPlan') }} since {{ expireAt }}
        </ng-button>
        <ng-button
          v-else-if="plan.id !== 'free' && isActive && !canceledAt"
          outlined
          large
          :color="inactiveButtonColor"
          @click="selectPlan"
        >
          {{ $t('subscription.renew') }}
        </ng-button>
        <ng-button v-else color="light" large @click="selectPlan">
          {{ $t('app.select') }}
        </ng-button>
      </template>
    </div>
  </article>
</template>

<script>
import SubscriptionPlanFeature from './SubscriptionPlanFeature.vue';
export default {
  name: 'SubscriptionPlan',
  components: { SubscriptionPlanFeature },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    description: {
      type: Object,
      required: true,
    },
    isYearly: {
      type: Boolean,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isAutopaymentActive: {
      type: Boolean,
      required: true,
    },
    currentPeriod: {
      type: String,
      default: 'null',
    },
    canceledAt: {
      type: String,
      default: 'null',
    },
    expireAt: {
      type: String,
      default: 'null',
    },
  },
  computed: {
    finalPrice() {
      return this.isYearly ? this.plan.yearlyPrice : this.plan.monthlyPrice;
    },
    isPeriodDifferent() {
      return (
        (this.currentPeriod === 'monthly' && this.isYearly) ||
        (this.currentPeriod === 'yearly' && !this.isYearly)
      );
    },
    inactiveButtonColor() {
      return this.plan.id === 'free' ? 'primary' : 'white';
    },
  },
  methods: {
    selectPlan() {
      this.$emit('select', this.plan);
    },
  },
};
</script>

<style lang="scss">
@import '@ngservices_front/scss/partials/typography.scss';

.plans__item,
.plans__item.panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  position: relative;
  padding: 25px 20px 76px;
  text-align: center;
  max-width: 400px;
  background-color: $free-bg-color;
  top: 0;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  overflow: hidden;
  flex: 1;
  transition: box-shadow 0.3s;
  backface-visibility: hidden;

  &:hover {
    @include elevation(16);
    z-index: 10;
  }

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    flex: none;
    margin-bottom: 30px;
  }

  @media (min-width: map-get($grid-breakpoints, 'sm')) and (max-width: map-get($grid-breakpoints, 'lg')) {
    border-radius: 0;
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  .plan-price--add {
    display: block;
    margin-top: -6px;
  }

  &.premium {
    background-color: $premium-bg-color;
    color: fade-out($premium-text-color, 0.33);

    .plans__item__header {
      background-color: $premium-bg-color;
      color: $premium-text-color;

      .plans__item__title {
        color: $premium-text-color;
      }
    }

    .plans__feature__item--extra {
      color: $premium-text-color;
    }

    a {
      color: #fff;
      border-bottom-color: hsla(0, 0%, 100%, 0.64);
    }

    a:active,
    a:focus,
    a:hover {
      opacity: 0.64;
    }
  }

  &.mini {
    background-color: $mini-bg-color;
    color: fade-out($mini-text-color, 0.33);

    .plans__item__header {
      background-color: $mini-bg-color;
      color: $mini-text-color;

      .plans__item__title {
        color: $mini-text-color;
      }
    }

    .plans__feature__item--extra {
      color: $mini-text-color;
    }

    a {
      color: #fff;
      border-bottom-color: hsla(0, 0%, 100%, 0.64);
    }

    a:active,
    a:focus,
    a:hover {
      opacity: 0.64;
    }
  }

  &.free {
    .plan-price--add {
      visibility: hidden;
    }
  }
}

.plans__item__btn {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.plans__item__info {
  font-size: 13px;
  margin-top: 30px;
  color: $light-textColor;
  letter-spacing: 0.5px;
}

.plans__item__header {
  margin: -25px -20px 25px;
  padding: 32px 20px 0px;
  background-color: $free-bg-color;
  color: $free-text-color;
  font-family: $heading-font-family;
}

.plans__item__title {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 2px;
  color: $free-text-color;
  font-size: 14px;
  font-weight: 500;
}

.plan-price {
  font-size: 36px;
  font-weight: 400;
}

.plan-price--small {
  font-size: 18px;

  .smaller-text {
    font-size: 0.9em;
  }
}

.plan-price--add {
  @extend .text-muted--light;
  display: none;
}

.plan-price__annually {
  display: none;
}

.plans__feature__item {
  position: relative;
  margin-bottom: 15px;
  line-height: 1.2;
}

.plans__feature__item--crossed {
  text-decoration: line-through;
  color: $light-textColor;
}

.plans__feature__subtitle {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 17px;
  margin-bottom: 15px;
  font-weight: 400;
  color: $mini-text-color;
}

.free {
  .plans__feature__subtitle {
    color: $free-text-color;
  }
}
</style>
