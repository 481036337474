import * as paymentService from '@/services/paymentService';
import * as counterService from '@/services/counterService';
import store from '@/store';
import * as subscriptionApi from '@/api/subscriptionApi';
import * as paymentApi from '@/api/paymentApi';

const STATUS_CHEKCING_INTERVAL = 1000;
const STATUS_CHEKCING_TIME_LIMIT_SHORT = 11 * 1000;
const STATUS_CHEKCING_TIME_LIMIT_LONG = 30 * 1000;

const loadSubscription = async () => {
  store.commit('subscription/SET_IS_DATA_LOADED', false);
  const url = new URL(location.href);
  const paymentAttempStatus = url.searchParams.get('payment-attempt-status');
  const scenario = url.searchParams.get('scenario');
  url.searchParams.delete('payment-attempt-status');
  url.searchParams.delete('scenario');
  url.searchParams.delete('subscription-canceled');
  window.history.replaceState({}, '', url.toString());

  if (scenario === 'invoiceRequested')
    store.commit('subscription/SET_SUBSCRIPTION_ALERT_TYPE', 'invoice_requested');

  if (paymentAttempStatus === 'success' || paymentAttempStatus === 'unknown') {
    const { status: paymentStatus, latest_error: lastPaymentError } =
      await paymentService.checkAutopaymentStatusUntilResolved({
        statusCheckingInterval: STATUS_CHEKCING_INTERVAL,
        statusCheckingTimeout:
          paymentAttempStatus === 'success'
            ? STATUS_CHEKCING_TIME_LIMIT_LONG
            : STATUS_CHEKCING_TIME_LIMIT_SHORT,
      });
    if (paymentStatus === 'active' && scenario === 'creation')
      store.commit('subscription/SET_SUBSCRIPTION_ALERT_TYPE', 'created');

    if (paymentStatus === 'failed' && scenario === 'creation')
      store.commit('subscription/SET_SUBSCRIPTION_ALERT_TYPE', 'creation_failed');

    if (!lastPaymentError && paymentStatus === 'active' && scenario === 'updating')
      store.commit('subscription/SET_SUBSCRIPTION_ALERT_TYPE', 'updated');

    if (!!lastPaymentError && scenario === 'updating')
      store.commit('subscription/SET_SUBSCRIPTION_ALERT_TYPE', 'updating_failed');
  }
  // TODO: common function for setting alert for all scenarios setAlert(scenario,paymentAttemptStatus, paymentStatus)
  if (scenario === 'cancellation') {
    store.commit('subscription/SET_SUBSCRIPTION_ALERT_TYPE', 'cancel');
  }
  const { subscription } = await subscriptionApi.getSubscription();
  if (!subscription) throw new Error('User subscription data is undefined');
  store.commit('subscription/SET_SUBSCRIPTION', subscription);
  store.commit('subscription/SET_IS_DATA_LOADED', true);
};

const buySubscription = async ({ plan, interval, userData }) => {
  const provider = userData.country === 'RU' ? 'yookassa' : 'stripe';
  const subscriptionData = {
    provider,
    plan,
    interval,
    userData,
  };
  counterService.sendCounterEvent({ name: 'buy-subscription', params: subscriptionData });
  const { redirect_url } = await paymentService.createPayment(subscriptionData);

  if (!redirect_url) throw new Error('URL for payment gateway is undefined');
  window.location.href = redirect_url;
};

const updateSubscription = async ({ plan, interval, userData }) => {
  const subscriptionData = {
    plan,
    interval,
    userData,
  };
  counterService.sendCounterEvent({ name: 'update-subscription', params: subscriptionData });
  await paymentService.updateAutopayment(subscriptionData);
  window.location.href = '/subscription?payment-attempt-status=success&scenario=updating';
};

const cancelSubscription = async () => {
  counterService.sendCounterEvent({ name: 'cancel-subscription' });
  await paymentService.deleteAutopayment();
  window.location.href = '/subscription?scenario=cancellation';
};

const restoreSubscription = () => paymentService.updateAutopaymentStatus('active');

const restoreAndLoadSubscription = async () => {
  counterService.sendCounterEvent({ name: 'restore-subscription' });
  await restoreSubscription();
  await loadSubscription();
  store.commit('subscription/SET_SUBSCRIPTION_ALERT_TYPE', 'restored');
};

const loadSubscriptionPlans = () => {
  if (window.subscriptionPlans) {
    store.commit('subscription/SET_AVAILABLE_PLANS', window.subscriptionPlans);
  } else {
    throw new Error('Subscription plans are undefined');
  }
};

const loadSubscriptionDiscounts = () => {
  if (window.yearlySubscriptionDiscount) {
    store.commit(
      'subscription/SET_YEARLY_SUBSCRIPTION_DISCOUNT',
      window.yearlySubscriptionDiscount,
    );
  } else {
    throw new Error('Yearly subscription discount is undefined');
  }
};

const loadSubscriptionSettings = () =>
  new Promise((resolve) => {
    loadSubscriptionPlans();
    loadSubscriptionDiscounts();
    resolve();
  });

const getSubscriptionInAdvance = ({ plan, interval }) =>
  subscriptionApi.getSubscriptionInAdvance({ plan, interval });

const requestSubscriptionInvoice = async ({
  selectedPlan,
  annualPayment,
  company_name,
  contact_person,
  contact_email,
  contact_phone,
  registration_number,
  tax_number,
  company_address,
  bank_details,
}) => {
  await paymentApi.requestInvoice({
    selectedPlan,
    annualPayment,
    company_name,
    contact_person,
    contact_email,
    contact_phone,
    registration_number,
    tax_number,
    company_address,
    bank_details,
  });
  window.location.href = '/subscription?scenario=invoiceRequested';
};

export {
  loadSubscription,
  buySubscription,
  loadSubscriptionPlans,
  loadSubscriptionSettings,
  updateSubscription,
  cancelSubscription,
  restoreSubscription,
  restoreAndLoadSubscription,
  getSubscriptionInAdvance,
  requestSubscriptionInvoice,
};
