import httpClient from '@/api/httpClient';

const getSubscription = () => httpClient.get('/subscription/').then((resp) => resp.data);

const getSubscriptionInAdvance = ({ plan, interval }) =>
  httpClient
    .get(`/subscription/convertion/?plan=${plan}&interval=${interval}`)
    .then((resp) => resp.data);

export { getSubscription, getSubscriptionInAdvance };
