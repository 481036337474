<template>
  <div class="subscription-checkout plan-choice__choosen">
    <v-row v-if="isReady">
      <v-col cols="12">
        <a
          href="#"
          class="plan-choice__back secondary-link secondary-link--small pull-right hidden-xs-only mb-4"
          @click="$emit('cancel')"
          :style="selectedPlan.id === 'free' ? 'visibility: hidden' : ''"
          ><i class="secondary-link__icon material-icons arrow_back"></i>
          {{ $t('subscription.changePlan') }}
        </a>
        <div class="choosen-plan">
          <v-row>
            <v-col cols="12" md="6">
              <ng-sheet color="light-secondary" class="mb-4">
                {{ $t('subscription.youSelected') }}
                <span class="h3 my-0 text-uppercase">{{ selectedPlan.name }}</span>
                —
                <ng-price
                  class="d-inline-block h3 my-0"
                  :amount="finalPrice.amount"
                  :currency="finalPrice.currency"
                  :monthly="selectedPlan.id !== 'free' && selectedPeriod === 'monthly'"
                  :yearly="selectedPlan.id !== 'free' && selectedPeriod === 'yearly'"
                  is-formatted
                ></ng-price>
              </ng-sheet>
              <ng-sheet class="mb-4" outlined v-if="!autopayment && selectedPlan.id !== 'free'">
                <span class="h4">{{ $t('app.contactInformation') }}</span>
                <v-radio-group
                  class="small mb-2"
                  v-model="userType"
                  row
                  v-if="$i18n.locale === 'ru'"
                >
                  <v-radio :label="$t('subscription.payByCard')" value="individual"></v-radio>
                  <v-radio :label="$t('subscription.payByWire')" value="entity"></v-radio>
                </v-radio-group>
                <NgFormVuelidatable
                  v-if="userType === 'individual'"
                  class="mt-0"
                  key="individual"
                  ref="userFormComponent"
                  :fields="userFormFields"
                  v-model="userFormModel"
                  :show-buttons="false"
                  :validation-rules="userFormValidationRules"
                ></NgFormVuelidatable>
                <template v-if="userType === 'entity'">
                  <NgFormVuelidatable
                    class="mt-0"
                    key="entity"
                    ref="entityFormComponent"
                    :fields="entityFormFields"
                    v-model="entityFormModel"
                    :show-buttons="false"
                    :validation-rules="entityFormValidationRules"
                  />

                  <ng-collapsible class="mt-4" :is-activator-custom="true">
                    <template v-slot:activator="{ toggleContent }">
                      <div class="mb-4">
                        Чтобы ускорить процесс оформления документов,
                        <a href="#" class="fake-link" @click.prevent="toggleContent"
                          >заполните&nbsp;реквизиты вашей организации</a
                        >
                      </div>
                    </template>
                    <template v-slot:content>
                      <ng-fieldset
                        :fields="entityExtendingFormFields"
                        v-model="entityExtendingFormModel"
                      ></ng-fieldset>
                    </template>
                  </ng-collapsible>
                </template>
              </ng-sheet>
              <ng-sheet
                class="small mb-4"
                outlined
                v-if="autopayment && plan !== 'free' && selectedPlan.id !== 'free'"
              >
                <ul>
                  <template v-if="plan !== selectedPlan.id && convertion.additional_days !== 0">
                    <li class="mb-2">
                      {{ $t('subscription.weConvertRemainingBalance') }}
                    </li>
                    <li
                      class="mb-2"
                      v-html="
                        $t('subscription.convertionResult', [
                          selectedPlan.name,
                          $t(`subscription.${selectedPeriod}`),
                          $tc('subscription.days', convertion.additional_days, {
                            count: convertion.additional_days,
                          }),
                        ])
                      "
                    ></li>
                  </template>
                  <li class="mb-2">
                    {{ $t('subscription.nowWeWillCharge', [finalPriceString]) }}
                  </li>
                  <li class="mb-2">
                    {{ $t('subscription.nextPaymentDetaield') }}
                    <ng-price
                      class="d-inline-block"
                      :amount="finalPrice.amount"
                      :currency="finalPrice.currency"
                      simple
                    ></ng-price>
                    {{ $t('subscription.willBeSheduled', [subscriptionInAdvanceExpireAt]) }}
                  </li>
                </ul>
              </ng-sheet>
              <template v-if="selectedPlan.id === 'free'">
                <ng-sheet class="small mb-4" outlined>
                  <div
                    v-html="$t('subscription.canceledSubscriptionWillBeActiveUntil', [expireAt])"
                  ></div>
                </ng-sheet>
                <ng-sheet class="small mb-4" outlined>
                  <p v-html="$t('subscription.toSwitchToPlan', ['Free'])"></p>
                  <ul class="mb-4">
                    <li>{{ $tc('subscription.planDescriptions.users', 1, { userCount: 1 }) }},</li>
                    <li>{{ $t('subscription.restrictions.numberOfResources') }},</li>
                    <li>{{ $t('subscription.restrictions.storage', ['5']) }},</li>
                    <li>{{ $t('subscription.restrictions.privateResources') }}.</li>
                  </ul>
                  <p>
                    {{ $t('subscription.restrictions.finalMessageFree') }}
                  </p>
                  <p v-html="$t('subscription.restrictions.finalMessageWebgisBlocking')"></p>
                </ng-sheet>
              </template>

              <ng-sheet
                class="small mb-4"
                outlined
                v-if="selectedPlan.id === 'mini' && plan === 'premium'"
              >
                <p v-html="$t('subscription.toSwitchToPlan', [this.selectedPlan.id])"></p>
                <ul class="mb-4">
                  <li>{{ $tc('subscription.planDescriptions.users', 1, { userCount: 1 }) }},</li>
                  <li>{{ $t('subscription.restrictions.trackers') }}</li>
                  <li>{{ $t('subscription.restrictions.storage', ['10']) }}.</li>
                </ul>
                <p>
                  {{ $t('subscription.restrictions.finalMessageMini') }}
                </p>
                <p v-html="$t('subscription.restrictions.finalMessageWebgisBlocking')"></p>
              </ng-sheet>

              <template v-if="selectedPlan.id === 'free'">
                <ng-button
                  block
                  class="mt-6"
                  color="primary"
                  large
                  @click="finishCheckout"
                  :loading="isProcessing"
                >
                  {{ submitButtonText }}
                </ng-button>
                <ng-button color="primary" block outlined large class="mt-2" href="/subscription">
                  {{ $t('app.goBack') }}
                </ng-button>
              </template>
            </v-col>
            <v-col cols="12" md="6" v-if="selectedPlan.id !== 'free'">
              <template v-if="userType !== 'entity'">
                <ng-sheet class="small mb-4" outlined v-if="!autopayment && plan === 'free'">
                  <ul>
                    <li class="mb-2" v-html="$t('subscription.weWillLinkCard')"></li>
                    <li class="mb-2">
                      {{ $t('subscription.nowWeWillCharge', [finalPriceString]) }}
                    </li>
                    <li class="mb-2">
                      {{ $t('subscription.nextPaymentDetaield') }}
                      <ng-price
                        class="d-inline-block"
                        :amount="finalPrice.amount"
                        :currency="finalPrice.currency"
                        simple
                      ></ng-price>
                      {{ $t('subscription.willBeSheduled', [subscriptionInAdvanceExpireAt]) }}
                    </li>
                    <li class="mb-2">{{ $t('subscription.cancelSubscriptionAnyTime') }}</li>
                  </ul>
                </ng-sheet>
                <ng-sheet
                  class="small mb-4"
                  outlined
                  v-if="!autopayment && plan !== 'free' && selectedPlan.id !== 'free'"
                >
                  <ul>
                    <li class="mb-2" v-html="$t('subscription.weWillLinkCard')"></li>
                    <template v-if="plan !== selectedPlan.id && convertion.additional_days !== 0">
                      <li class="mb-2">
                        {{ $t('subscription.weConvertRemainingBalanceNoAutopayments') }}
                      </li>
                      <li
                        class="mb-2"
                        v-html="
                          $t('subscription.convertionResultNoAutopayments', [
                            selectedPlan.name,
                            $t(`subscription.${selectedPeriod}`),
                            $tc('subscription.days', convertion.additional_days, {
                              count: convertion.additional_days,
                            }),
                          ])
                        "
                      ></li>
                    </template>
                    <li class="mb-2">
                      {{ $t('subscription.nowWeWillCharge', [finalPriceString]) }}
                    </li>
                    <li class="mb-2">
                      {{ $t('subscription.nextPaymentDetaield') }}
                      <ng-price
                        class="d-inline-block"
                        :amount="finalPrice.amount"
                        :currency="finalPrice.currency"
                        simple
                      ></ng-price>
                      {{ $t('subscription.willBeSheduled', [subscriptionInAdvanceExpireAt]) }}
                    </li>
                    <li class="mb-2">{{ $t('subscription.cancelSubscriptionAnyTime') }}</li>
                  </ul>
                </ng-sheet>
              </template>

              <ng-sheet class="mb-4" outlined>
                <div>
                  <span class="h3 my-0 mr-2">{{ $t('subscription.total') }}:</span>
                  <ng-price
                    class="d-inline-block h3 my-0"
                    :amount="finalPrice.amount"
                    :currency="finalPrice.currency"
                    is-formatted
                  ></ng-price>
                </div>
                <div class="small text-muted" v-if="userType !== 'entity'">
                  {{ $t('subscription.nextPaymentDetaield') }}
                  <ng-price
                    class="d-inline-block"
                    :amount="finalPrice.amount"
                    :currency="finalPrice.currency"
                    simple
                  ></ng-price>
                  {{ $t('subscription.willCharge', [subscriptionInAdvanceExpireAt]) }}
                </div>
              </ng-sheet>
              <ng-button
                block
                color="primary"
                large
                @click="finishCheckout"
                :loading="isProcessing"
              >
                {{ submitButtonText }}
              </ng-button>
              <p
                v-if="userType !== 'entity' && selectedPlan.id !== 'free'"
                class="small text-muted mt-4"
                v-html="$t('subscription.agreement', [nextgisTermsPlansUrl])"
              ></p>
            </v-col>
          </v-row>
        </div>
        <div class="text-center">
          <a
            href="#"
            class="plan-choice__back secondary-link secondary-link--small d-inline-block d-sm-none"
            @click="$emit('cancel')"
            v-if="selectedPlan.id !== 'free'"
          >
            <i class="secondary-link__icon material-icons arrow_back"></i
            >{{ $t('subscription.changePlan') }}
          </a>
        </div>
      </v-col>
    </v-row>
    <v-row v-else class="ma-0">
      <v-col>
        <ng-sheet class="mt-4">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </ng-sheet>
      </v-col>
    </v-row>
  </div>
  <!-- /.plan-choice__choosen -->
</template>

<script>
import { mapState } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import { phoneNumber } from '@ngservices_front/js/phoneValidator';
import * as subscriptionService from '@/services/subscriptionService';
import { fireError } from '@ngservices_front/services/errorService';
import { formatDate, convertUtcToLocal } from '@/utils/dateUtils';
import { getPriceString } from '@/utils/priceUtils';
import getCookie from '@/utils/getCookie';
import NgFormVuelidatable from '@ngservices_front/components/ui/NgFormVuelidatable';
import NgCollapsible from '@/components/ui/NgCollapsible/NgCollapsible';

export default {
  name: 'SubscriptionCheckout',
  components: { NgFormVuelidatable, NgCollapsible },
  props: {
    selectedPlan: {
      type: Object,
      required: true,
    },
    selectedPeriod: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      csrfToken: getCookie('ngid_csrftoken'),
      userType: 'individual',
      userFormFields: {
        firstName: {
          name: 'firstName',
          label: this.$i18n.t('profile.firstName'),
          widget: 'text',
          col: 6,
          errors: [],
          serverErrors: '',
          attrs: {
            filled: true,
          },
        },
        lastName: {
          name: 'lastName',
          label: this.$i18n.t('profile.lastName'),
          widget: 'text',
          col: 6,
          errors: [],
          serverErrors: '',
          attrs: {
            filled: true,
          },
        },
        email: {
          name: 'email',
          label: this.$i18n.t('profile.email'),
          widget: 'text',
          errors: [],
          serverErrors: '',
          attrs: {
            filled: true,
            readonly: true,
          },
        },
        country: {
          name: 'country',
          label: this.$i18n.t('profile.country'),
          widget: 'autocomplete',
          options: window.paymentCountries,
          errors: [],
          serverErrors: '',
          attrs: {
            filled: true,
          },
        },
      },
      userFormModel: {
        firstName: '',
        lastName: '',
        email: '',
        country: window.paymentCountry,
      },
      userFormValidationRules: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        country: { required },
      },
      entityFormFields: {
        company_name: {
          name: 'company_name',
          label: 'Название организации*',
          widget: 'text',
          col: 6,
          errors: [],
          serverErrors: '',
          attrs: {
            filled: true,
          },
        },
        contact_person: {
          name: 'contact_person ',
          label: 'Контактное лицо*',
          widget: 'text',
          col: 6,
          errors: [],
          serverErrors: '',
          attrs: {
            filled: true,
          },
        },
        contact_email: {
          name: 'contact_email',
          label: 'Email*',
          widget: 'text',
          errors: [],
          serverErrors: '',
          attrs: {
            filled: true,
          },
        },
        contact_phone: {
          name: 'contact_phone',
          label: 'Телефон*',
          widget: 'phone',
          errors: [],
          serverErrors: '',
          attrs: {
            filled: true,
          },
        },
        selectedPlan: {
          name: 'selected-plan',
          widget: 'text',
          errors: [],
          serverErrors: '',
          attrs: {
            type: 'hidden',
          },
        },
        annualPayment: {
          name: 'annual-payment',
          widget: 'text',
          errors: [],
          serverErrors: '',
          attrs: {
            type: 'hidden',
          },
        },
      },
      entityFormModel: {
        companyName: '',
        contactPerson: '',
        email: '',
        contact_phone: '',
        selectedPlan: '',
        annualPayment: '',
      },
      entityFormValidationRules: {
        company_name: { required },
        contact_person: { required },
        contact_email: { required, email },
        contact_phone: { required, phoneNumber },
      },
      entityExtendingFormFields: {
        registration_number: {
          name: 'registration_number',
          label: 'ОГРН',
          widget: 'text',
          col: 12,
          errors: [],
          serverErrors: '',
          attrs: {
            filled: true,
          },
        },
        tax_number: {
          name: 'tax_number',
          label: 'ИНН/КПП',
          widget: 'text',
          col: 12,
          errors: [],
          serverErrors: '',
          attrs: {
            filled: true,
          },
        },
        company_address: {
          name: 'company_address',
          label: 'Адрес организации',
          widget: 'text',
          col: 12,
          errors: [],
          serverErrors: '',
          attrs: {
            filled: true,
          },
        },
        bank_details: {
          name: 'bank_details',
          label: 'Банковские реквизиты',
          widget: 'text',
          col: 12,
          errors: [],
          serverErrors: '',
          attrs: {
            filled: true,
          },
        },
      },
      entityExtendingFormModel: {
        registration_number: '',
        tax_number: '',
        company_address: '',
        bank_details: '',
      },
      isProcessing: false,
      subscriptionInAdvance: undefined,
      convertion: undefined,
    };
  },
  computed: {
    ...mapState('subscription', ['plan', 'autopayment', 'expireAt']),
    ...mapState('baseUser', ['firstName', 'lastName', 'email', 'company', 'phone']),
    finalPrice() {
      return this.selectedPeriod === 'yearly'
        ? this.selectedPlan.yearlyPrice
        : this.selectedPlan.monthlyPrice;
    },
    finalPriceString() {
      return getPriceString(this.finalPrice);
    },
    submitButtonText() {
      if (this.selectedPlan.id === 'free') return this.$t('subscription.cancelSubscription');
      if (this.userType === 'entity') return this.$t('subscription.issueInvoice');
      return this.$t('subscription.pay');
    },
    isReady() {
      return (
        this.selectedPlan.id === 'free' || (!!this.convertion && this.subscriptionInAdvanceExpireAt)
      );
    },
    subscriptionInAdvanceExpireAt() {
      return (
        this.subscriptionInAdvance &&
        formatDate(convertUtcToLocal(this.subscriptionInAdvance.expire_at), this.$i18n.locale)
      );
    },
    nextgisTermsPlansUrl() {
      return this.$i18n.locale === 'ru'
        ? 'https://nextgis.ru/terms-plans/'
        : 'https://nextgis.com/terms-plans/';
    },
  },
  async created() {
    this.userFormModel = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
    };
    this.entityFormModel = {
      company_name: this.company,
      contact_person: `${this.firstName} ${this.lastName}`,
      contact_email: this.email,
      contact_phone: this.phone,
      selectedPlan: this.selectedPlan.id,
      annualPayment: this.selectedPeriod === 'yearly' ? 'on' : 'off',
    };
    if (this.selectedPlan.id !== 'free') {
      try {
        const { subscription, convertion } = await subscriptionService.getSubscriptionInAdvance({
          plan: this.selectedPlan.id,
          interval: this.selectedPeriod,
        });
        this.subscriptionInAdvance = subscription;
        this.convertion = convertion;
      } catch (e) {
        fireError(e);
      }
    }
  },
  methods: {
    async finishCheckout() {
      try {
        const goToFreeForm = document.querySelector('.go-to-free-form');
        this.isProcessing = true;
        if (this.selectedPlan.id === 'free') {
          if (this.autopayment) {
            await subscriptionService.cancelSubscription();
          } else {
            if (goToFreeForm) goToFreeForm.submit();
          }
        } else {
          if (this.userType === 'individual') {
            if (this.autopayment) {
              await subscriptionService.updateSubscription({
                plan: this.selectedPlan.id,
                interval: this.selectedPeriod,
                userData: this.userFormModel,
              });
            } else {
              if (this.$refs.userFormComponent && this.$refs.userFormComponent.validate())
                await subscriptionService.buySubscription({
                  plan: this.selectedPlan.id,
                  interval: this.selectedPeriod,
                  userData: this.userFormModel,
                });
            }
          } else {
            if (this.$refs.entityFormComponent && this.$refs.entityFormComponent.validate())
              await subscriptionService.requestSubscriptionInvoice({
                selectedPlan: this.selectedPlan.id,
                annualPayment: 'on',
                ...this.entityFormModel,
                ...this.entityExtendingFormModel,
              });
          }
        }
      } catch (e) {
        fireError(e);
      } finally {
        this.isProcessing = false;
      }
    },
  },
};
</script>

<style lang="scss">
.choosen-plan {
  clear: both;
}

.choosen-plan__header {
  color: $premium-text-color;

  &.free {
    color: $free-text-color;

    .choosen-plan__descr {
      background-color: #fff;
    }

    .choosen-plan__price {
      background-color: $free-bg-color;
      color: $free-text-color;
    }

    .choosen-plan__info {
      color: $text-base;
    }
  }

  &.mini {
    .choosen-plan__descr {
      background-color: rgba($mini-bg-color, 0.82);
    }

    .choosen-plan__price {
      background-color: $mini-bg-color;
    }
  }
}

.choosen-plan__settings {
  display: flex;
  width: 100%;

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    flex-direction: column;
  }
}

.choosen-plan__warning {
  padding: 17px 40px 22px 25px;
  background-color: $ng-secondary;
  color: $text-base;

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    padding-right: 25px;
  }
}

.choosen-plan__descr {
  width: 100%;
}

.choosen-plan__descr,
.choosen-plan__price {
  display: table-cell;
  padding: 17px 25px 22px;
  vertical-align: middle;
  background: rgba($premium-bg-color, 0.78);
  min-width: 180px;

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    display: block;
    text-align: center;
  }
}

.choosen-plan__price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: $premium-bg-color;
  white-space: nowrap;
  font-weight: 400;
  padding-top: 0;
  padding-bottom: 5px;
  width: 220px;
  line-height: 1.2;

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    padding-top: 8px;
    width: 100%;
  }

  .plan-price {
    font-family: $heading-font-family;
  }
}

.choosen-plan__price__info {
  color: $label-warning-color;
  font-size: 14px;
}

.choosen-plan__name {
  margin-left: 5px;
  font-size: 18px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.choosen-plan__info {
  color: rgba($premium-text-color, 0.9);
  margin-top: 6px;
  font-size: 14px;
}

.choosen-plan__payment {
  background-color: #fff;
  padding: 30px 25px 30px;
}

.choosen-plan__payment-text {
  margin-bottom: 12px;
}
.v-radio label {
  margin-bottom: 0;
}
</style>
