<template>
  <ngid-base-layout class="webgis-create" fluid>
    <div v-if="!(url_oauth && status && isDataLoaded)" class="text-center">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <v-row v-else>
      <v-col cols="12" lg="8">
        <h1>{{ $t('webgis.settings') }}</h1>
        <p v-html="$t('webgis.description')"></p>
        <template v-if="status === 'wait_for_create'">
          <div>
            <p>
              <span v-html="$t('webgis.isBeingCreated')"></span>&nbsp;<a
                :href="`mailto:${email}`"
                >{{ email }}</a
              >
            </p>
            <div class="small text--secondary" v-html="$t('webgis.creatingProblem')"></div>
          </div>
        </template>
        <template v-else-if="status">
          <ng-sheet class="mb-2 d-inline-block" color="light-secondary">
            <p>
              {{ $t('webgis.address') }} —
              <strong>
                <a
                  :href="`${url_oauth}`"
                  target="_blank"
                  onclick="ym(86035883,'reachGoal','webgis_link')"
                  >{{ urlWithoutScheme }}</a
                >
              </strong>
            </p>
            <!-- <div class="small text--secondary">
              <p>
                <span v-html="$t('webgis.loginSent')"></span>&nbsp;<a
                  class="secondary-link"
                  :href="`mailto:${email}`"
                  >{{ email }}</a
                ><br />
                <span v-html="$t('webgis.haventReceivedEmail')"></span>
              </p>
            </div> -->
          </ng-sheet>
          <template v-if="status === 'disabled'">
            <v-alert class="mt-6" type="error" outlined icon="mdi-cancel" prominent>
              <div v-html="$t('webgis.isBlocked')"></div>
            </v-alert>
          </template>
          <template v-else>
            <ng-collapsible class="mt-6" activator-as-link>
              <template slot="activator">
                <span class="h4">{{ $t('webgis.changePassword') }}</span>
              </template>
              <template slot="content">
                <div class="pt-2">
                  <p class="small" v-html="$t('webgis.changePasswordDescription')"></p>
                  <form
                    id="ngw-password-change-form"
                    class="ngw-password-change-form form--small"
                    action="/webgis/"
                    method="POST"
                  >
                    <change-web-gis-password-form></change-web-gis-password-form>
                  </form>
                </div>
              </template>
            </ng-collapsible>
            <WebgisSuccessModal v-model="isWebgisJustStarted" />
          </template>
        </template>
        <WebgisRelatedInfo class="mt-12" />
      </v-col>
    </v-row>
    <slot></slot>
  </ngid-base-layout>
</template>

<script>
import { mapState } from 'vuex';
import { get_query_value, removeURLParameter } from '@ngservices_front/js/utilities';
import { getUrlByLocale } from '@ngservices_front/services/UrlService';
import NgidBaseLayout from '@/layouts/NgidBaseLayout';
import NgCollapsible from '@/components/ui/NgCollapsible/NgCollapsible';
import ChangeWebGisPasswordForm from '@/components/ChangeWebGisPasswordForm/ChangeWebGisPasswordForm.vue';
import WebgisSuccessModal from '@/components/webgis/WebgisSuccessModal.vue';
import WebgisRelatedInfo from '@/components/webgis/WebgisRelatedInfo.vue';

export default {
  name: 'WebgisSettings',
  components: {
    NgidBaseLayout,
    NgCollapsible,
    ChangeWebGisPasswordForm,
    WebgisSuccessModal,
    WebgisRelatedInfo,
  },
  data() {
    return {
      isWebgisJustStarted: false,
    };
  },
  computed: {
    ...mapState('webgis', ['url', 'url_oauth', 'status']),
    ...mapState('baseUser', ['email']),
    ...mapState('subscription', ['isDataLoaded']),
    urlWithoutScheme() {
      return this.url.replace(/https?:\/\//i, '');
    },
    connectUrl() {
      return getUrlByLocale('nextgis_connect_docs', this.$i18n.locale);
    },
    connectVideoUrl() {
      return this.$i18n.locale === 'ru'
        ? 'https://www.youtube.com/embed/qIByQEqZ4oQ'
        : 'https://www.youtube.com/embed/Wwx1mowUAL4';
    },
    webgisDocsUrl() {
      return getUrlByLocale('webgis_docs', this.$i18n.locale);
    },
  },
  watch: {
    isWebgisJustStarted(val) {
      if (!val)
        window.history.replaceState({}, document.title, removeURLParameter('webgis-status'));
    },
  },
  created() {
    this.isWebgisJustStarted = get_query_value('webgis-status') === 'started';
  },
};
</script>

<style lang="scss" scoped>
.ng-sheet__external-icon {
  position: absolute;
  right: 24px;
}
</style>
