<template>
  <div class="plan-choice__plans clearfix">
    <div class="plans pb-10">
      <v-row class="plans__columns my-0">
        <v-col cols="12" class="plans__switcher-col py-0">
          <div ref="annual-switcher" class="plans__annual-switcher dual-switcher mb-4">
            <label class="dual-switcher__label" data-switch-left data-switch-state="0">
              <span class="hidden-sm-and-down">{{ $t('subscription.payMonthly') }}</span
              ><span class="hidden-md-and-up">{{ $t('subscription.payMonthlyShort') }}</span>
            </label>
            <label class="dual-switcher__control switcher-control">
              <input
                name="annual-payment"
                type="checkbox"
                class="dual-switcher__control-checkbox switcher-control__checkbox"
              />
              <span class="switcher-control__slider"></span>
            </label>
            <label class="dual-switcher__label" data-switch-right data-switch-state="1">
              <span class="hidden-sm-and-down">{{ $t('subscription.payAnnually') }}</span
              ><span class="hidden-md-and-up">{{ $t('subscription.payAnnuallyShort') }}</span
              ><span class="accent--text ml-2">{{ yearlySubscriptionDiscountLabel }}%</span>
            </label>
          </div>
        </v-col>
        <v-col
          v-for="planKey in Object.keys(availablePlansMap)"
          cols="12"
          sm="4"
          class="plans__col py-sm-0"
          :key="planKey"
        >
          <SubscriptionPlan
            :plan="availablePlansMap[planKey]"
            :is-yearly="isYearly"
            :is-active="planKey === plan"
            :is-autopayment-active="autopayment"
            :current-period="interval"
            :description="subscriptionPlanDescriptions[planKey]"
            :canceled-at="canceledAt"
            :expire-at="expireAt"
            @select="selectSubscription"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DualSwitcher from '@/components/ui/DualSwitcher/DualSwitcher';
import SubscriptionPlan from './SubscriptionPlan.vue';
import { SUBSCRIPTION_PLAN_DESCRIPTIONS } from '@/config';

export default {
  name: 'SubscriptionPlans',
  components: { SubscriptionPlan },
  data() {
    return {
      isYearly: false,
      annualSwitcher: undefined,
      subscriptionPlanDescriptions: SUBSCRIPTION_PLAN_DESCRIPTIONS,
    };
  },
  computed: {
    ...mapState('subscription', [
      'plan',
      'interval',
      'yearlySubscriptionDiscount',
      'autopayment',
      'canceledAt',
      'expireAt',
    ]),
    ...mapGetters('subscription', ['availablePlansMap']),
    yearlySubscriptionDiscountLabel() {
      return `-${this.yearlySubscriptionDiscount * 100}`;
    },
  },
  mounted() {
    if (this.interval === 'monthly') this.isYearly = false;
    this.initAnnualSwitcher();
  },
  methods: {
    initAnnualSwitcher() {
      const annualSwitcherNode = this.$refs['annual-switcher'];

      if (annualSwitcherNode) {
        this.annualSwitcher = new DualSwitcher({
          el: annualSwitcherNode,
          state: this.isYearly ? 1 : 0,
        });
      }
      if (this.annualSwitcher)
        annualSwitcherNode.addEventListener('switcherChanged', (e) => {
          this.isYearly = e.detail.value === 1 ? true : false;
        });
    },
    selectSubscription(selectedPlan) {
      this.$emit('selectSubscription', {
        plan: selectedPlan,
        period: this.isYearly ? 'yearly' : 'monthly',
      });
    },
  },
};
</script>

<style lang="scss">
@import '@ngservices_front/scss/partials/typography.scss';
@import '~vuetify/src/styles/tools/_elevation.sass';
$wrapper-gap: 24px;
$wrapper-gap-sm-top: 46px;

.plan-choice-wrapper {
  max-width: 1100px;
  position: relative;
  overflow: hidden;
  transition: height 0.6s;
  backface-visibility: hidden;
  padding: $wrapper-gap $wrapper-gap 5px;
  margin: -#{$wrapper-gap} -#{$wrapper-gap} -5px;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    margin-top: -$wrapper-gap-sm-top;
    padding-top: $wrapper-gap-sm-top;
  }
}

.plan-choice {
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    margin-top: -$wrapper-gap-sm-top;
    padding-top: 2px;
  }

  .panel {
    margin-bottom: 0;
  }

  .btn {
    margin-top: 0;
  }
}

.plan-choice__back,
.admin__panel__content .plan-choice__back {
  color: $text-base;
  border-bottom: 0;

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    margin-bottom: 0;
    margin-top: 16px;
  }

  .secondary-link__icon {
    position: relative;
    top: -1px;
    color: #212121;
  }

  &:hover {
    color: $link-hover-color;

    .secondary-link__icon {
      color: $link-hover-color;
    }
  }
}

.plan-choice__plans {
  position: relative;
  max-height: 2500px;
}

.plans {
  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    margin-left: 0;
    margin-right: 0;
  }

  &__columns {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  &__switcher-col {
    display: flex;
    justify-content: flex-end;

    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      justify-content: flex-start;
    }

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.plans__col {
  display: flex;

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    flex-basis: 100%;
    max-width: 100%;

    &:last-child .plans__item {
      margin-bottom: 0;
    }
  }
}

.lte-ie9 .plans__col {
  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    width: 100%;
  }
}

.plans__feature {
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  margin-bottom: 24px;
}

.plans__feature--bottom {
  margin-top: auto;
  margin-bottom: 0;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    height: 260px;
  }

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    height: 190px;
  }
}

.plan-choice__period {
  $slider-padding: 16px;
  background-color: $warning;
  padding: 0 $slider-padding;
  margin-top: 36px;
  margin-bottom: 15px;

  .noUi-handle:after,
  .noUi-origin[style^='left: 0'] .noUi-handle:after {
    background-color: $warning;
    border-color: $warning;
  }

  .noUi-origin {
    right: -$slider-padding;
  }

  .noUi-pips {
    color: #fff;
  }
}

.plan-choice__period-wrapper {
  &.table-block,
  .table-block__cell {
    @media (max-width: 580px) {
      display: block;
    }
  }
}

.plan-choice__period-text {
  position: relative;
  top: 8px;
  font-weight: 400;
  padding-right: 15px;
  user-select: none;

  @media (max-width: 580px) {
    padding-right: 0;
    top: 0;
    margin-top: 6px;
  }
}

.plans-morelink,
.plans-enterprise {
  margin-top: 40px;
}

.plans-enterprise__text {
  font-size: 14px;

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    margin-bottom: 12px;
  }
}

.plans-enterprise__btn {
  margin-left: 12px;

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    float: none;
    margin-left: 0;
  }
}

.plans__annual-switcher {
  opacity: 1;
  transition: opacity 1s;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    margin-top: -3px;
  }
}
</style>
