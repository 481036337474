<template>
  <SidebarLayout class="ngid-base-layout" v-bind="$attrs" v-on="$listeners">
    <template v-slot:header-action>
      <ng-button
        v-if="!baseUser.webgisUrl && baseApp.currentPage.id != 'ngw_instance_create'"
        href="/webgis/create"
        color="primary"
      >
        {{ $t('createWebGIS') }}
      </ng-button>
      <a href="/subscription" class="plan-info withoutripple">
        <span class="plan-info__label">{{ compoundPlan }}</span>
      </a>
    </template>
    <template v-slot:header-menu></template>
    <template v-slot:sidebar>
      <ngid-sidebar-menu></ngid-sidebar-menu>
    </template>
    <slot></slot>
  </SidebarLayout>
</template>

<i18n>
  {
    "en": {
      "createWebGIS": "Create Web GIS"
    },
    "ru": {
      "createWebGIS": "Создать Веб ГИС"
    }
  }
</i18n>
<script>
import SidebarLayout from '@ngservices_front/layouts/SidebarLayout.vue';
import { mapState } from 'vuex';
import NgidSidebarMenu from '@/components/NgidSidebarMenu.vue';

export default {
  name: 'NgidBaseLayout',
  components: {
    SidebarLayout,
    NgidSidebarMenu,
  },
  computed: {
    ...mapState(['baseUser', 'baseApp']),
    ...mapState('subscription', ['plan']),
    compoundPlan() {
      return this.plan || this.baseUser.plan;
    },
  },
};
</script>

<style lang="scss" scoped>
.ngid-base-layout {
  :deep(.main),
  :deep(.header__container-2),
  :deep(.footer__container-2) {
    width: 100%;
    max-width: $content-max-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.plan-info {
  color: inherit;
  text-decoration: none;
  margin-left: 12px;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    .plan-info__label {
      background-color: $success;
      color: #fff;

      .icon-caret {
        color: #fff;
      }
    }
  }

  &__label {
    color: $success;
    padding: 2px 5px;
    border: 1px solid transparent;
    border-radius: 4px;
    position: relative;
    top: -1px;

    .icon-caret {
      margin-left: -3px;
      margin-right: -6px;
      opacity: 0.76;
    }
  }
}

.plan-info__details {
  display: none;
  color: fade-out($primary, 0.6);
  font-size: 12px;
  line-height: 1.2;
  vertical-align: middle;
  margin-left: 8px;
  margin-top: -2px;

  &:hover {
    color: $primary;
  }

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    display: inline-block;
  }
}
</style>
