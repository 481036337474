const config = {
  serviceName: 'NextGIS ID',
  email: 'info@nextgis.com',
  withAuthorization: true,
  headerFixed: true,
  customUrls: {
    logout: '/silent_logout',
    profile: '/profile',
    //login: define custom login,
    //signup: define custom signup,
  },
};

export const SUBSCRIPTION_PLAN_DESCRIPTIONS = {
  free: {
    topList: [
      { key: 'subscription.planDescriptions.limitedResources', extra: false },
      {
        key: 'subscription.planDescriptions.users',
        count: 1,
        options: { userCount: 1 },
        extra: false,
      },
      {
        key: 'subscription.planDescriptions.storage',
        options: { storageVolume: '5 Gb' },
        extra: false,
      },
      {
        key: 'subscription.planDescriptions.basemaps',
        extra: false,
      },
      {
        key: 'subscription.planDescriptions.editing',
        extra: false,
      },
      {
        key: 'subscription.planDescriptions.toolbox',
        extra: false,
      },
    ],
    bottomList: [
      {
        key: 'subscription.planDescriptions.freeSoftware',
        urlName: 'nextgis_site',
        extra: false,
      },
    ],
  },
  mini: {
    topList: [
      { key: 'subscription.planDescriptions.unlimitedResources', extra: true },
      {
        key: 'subscription.planDescriptions.users',
        count: 1,
        options: { userCount: 1 },
        extra: false,
      },
      {
        key: 'subscription.planDescriptions.storage',
        options: { storageVolume: '10 Gb' },
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.basemaps',
        extra: false,
      },
      {
        key: 'subscription.planDescriptions.editing',
        extra: false,
      },
      {
        key: 'subscription.planDescriptions.toolbox',
        extra: false,
      },
      {
        key: 'subscription.planDescriptions.cors',
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.lookupTables',
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.tracking',
        count: 1,
        options: { trackerCount: 1, apps: 'Mobile' },
        extra: true,
      },
    ],
    bottomList: [
      {
        key: 'subscription.planDescriptions.proSoftware',
        urlName: 'nextgis_site',
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.softwareUpdates',
        extra: true,
      },
    ],
  },
  premium: {
    topList: [
      { key: 'subscription.planDescriptions.unlimitedResources', extra: false },
      {
        key: 'subscription.planDescriptions.users',
        count: 5,
        options: { userCount: 5 },
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.storage',
        options: { storageVolume: '50 Gb' },
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.basemaps',
        extra: false,
      },
      {
        key: 'subscription.planDescriptions.editing',
        extra: false,
      },
      {
        key: 'subscription.planDescriptions.toolboxExtended',
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.cors',
        extra: false,
      },
      {
        key: 'subscription.planDescriptions.lookupTables',
        extra: false,
      },
      {
        key: 'subscription.planDescriptions.tracking',
        count: 5,
        options: { trackerCount: 5, apps: 'Mobile, Collector' },
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.collector',
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.privacy',
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.domainName',
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.branding',
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.speed',
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.support',
        extra: true,
      },
    ],
    bottomList: [
      {
        key: 'subscription.planDescriptions.proSoftwarePremium',
        urlName: 'nextgis_site',
        extra: true,
      },
      {
        key: 'subscription.planDescriptions.softwareUpdates',
        extra: false,
      },
      {
        key: 'subscription.planDescriptions.offlineInstallers',
        extra: true,
      },
    ],
  },
};

export const LOCALIZATION = false;

export default config;
