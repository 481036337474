<template>
  <div class="change-webgis-password-form">
    <ng-form-vuelidatable
      :fields="formFields"
      v-model="formModel"
      :validation-rules="validationRules"
      :btn-text="$t('webgis.changePasswordBtn')"
      :btnAttrs="{
        color: 'primary',
        large: true,
        class: 'mt-4',
      }"
      use-case="change_webgis_password"
      :loading="isFormSubmitting"
      @submit="submit"
    >
    </ng-form-vuelidatable>
    <v-dialog v-model="isModalShown" width="420">
      <ng-sheet>
        <h3>{{ $t('webgis.passwordChanged') }}</h3>
        <p>{{ $t('webgis.passwordChangedNotification') }}</p>
        <ng-button color="primary" block @click="isModalShown = false">{{
          $t('app.gotIt')
        }}</ng-button>
      </ng-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { changeWebgisPassword } from '@/api/webgisApi';
import { fireError } from '@ngservices_front/services/errorService';
import NgFormVuelidatable from '@ngservices_front/components/ui/NgFormVuelidatable';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';

export default {
  components: { NgFormVuelidatable },
  name: 'ChangeWebGisPasswordForm',
  data() {
    return {
      formFields: {
        ngw_new_password: {
          name: 'ngw_new_password',
          label: this.$t('webgis.newWebgisPassword'),
          widget: 'password',
          serverErrors: '',
          errors: [],
          attrs: {
            filled: true,
          },
        },
        ngw_new_password_confirm: {
          name: 'ngw_new_password_confirm',
          label: this.$t('webgis.confirmNewWebgisPassword'),
          widget: 'password',
          serverErrors: '',
          errors: [],
          attrs: {
            filled: true,
          },
        },
      },
      formModel: {
        ngw_new_password: '',
        ngw_new_password_confirm: '',
      },
      validationRules: {
        ngw_new_password: {
          required,
          minLength: minLength(6),
        },
        ngw_new_password_confirm: {
          required,
          sameAs: sameAs(function () {
            return this.formModel && this.formModel.ngw_new_password;
          }),
        },
      },
      isFormSubmitting: false,
      isModalShown: false,
    };
  },
  methods: {
    async submit() {
      try {
        await changeWebgisPassword(this.formModel.ngw_new_password, {
          useCase: 'change_webgis_password',
        });
        this.isModalShown = true;
      } catch (e) {
        fireError(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
